/* .ant-card{box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%); border: none;} */
.ant-card{box-shadow: 0 1px 1px 0 rgb(60 64 67 / 8%), 0 1px 3px 0 rgb(60 64 67 / 16%); border: none;}
.ant-modal-body .ant-card, .ant-drawer-body .ant-card, .no-shadow-card .ant-card{box-shadow:none; border:1px solid var(--border-color);}

.stripped .ant-table-tbody>tr:nth-child(even) {
    background-color: #fafafa;
}
.stripped .ant-table-tbody>tr.ant-table-row:hover>td, .stripped .ant-table-tbody>tr>td.ant-table-cell-row-hover{
    background: #f1f1f1;
}
.white-head .ant-table .ant-table-thead>tr>th{
    background-color: #fff;
}