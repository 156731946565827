:root {
  --body-bg: #f3f4fa;
  --anchor-color: #1890ff;
  --font-color: rgba(0, 0, 0, .85);
  --border-color: #f0f0f0;
  --navbar-bg: #fff;
  --cscroll-thumb-bg: rgba(50, 50, 50, 0.3);
  --cscroll-thumb-bg-hover: #666;
  --pills-bg: #e1e1e1;
  --pills-bg-active: #1890ff;
  --active-list-item-bg: #1890ff;
}

body {
  line-height: 1.3;
  background: var(--body-bg);
}

.parent {
  padding-top: 1px;
  margin-top: -1px;
}

/** Margins 1-10(+1), 15-50(+5), 60-100(+10) **/
.m0 {
  margin: 0 !important;
}

.m1 {
  margin: 1px !important;
}

.ml1 {
  margin-left: 1px !important;
}

.mr1 {
  margin-right: 1px !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mb1 {
  margin-bottom: 1px !important;
}

.m2 {
  margin: 2px !important;
}

.ml2 {
  margin-left: 2px !important;
}

.mr2 {
  margin-right: 2px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.m3 {
  margin: 3px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.mr3 {
  margin-right: 3px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.m4 {
  margin: 4px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.m5 {
  margin: 5px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.m6 {
  margin: 6px !important;
}

.ml6 {
  margin-left: 6px !important;
}

.mr6 {
  margin-right: 6px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mb6 {
  margin-bottom: 6px !important;
}

.m7 {
  margin: 7px !important;
}

.ml7 {
  margin-left: 7px !important;
}

.mr7 {
  margin-right: 7px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mb7 {
  margin-bottom: 7px !important;
}

.m8 {
  margin: 8px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.m9 {
  margin: 9px !important;
}

.ml9 {
  margin-left: 9px !important;
}

.mr9 {
  margin-right: 9px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mb9 {
  margin-bottom: 9px !important;
}

.m10 {
  margin: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.m25 {
  margin: 25px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.m30 {
  margin: 30px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.m35 {
  margin: 35px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.m40 {
  margin: 40px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.m45 {
  margin: 45px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.m50 {
  margin: 50px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.m60 {
  margin: 60px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.m70 {
  margin: 70px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.m80 {
  margin: 80px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.m90 {
  margin: 90px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.m100 {
  margin: 100px !important;
}

.ml100 {
  margin-left: 100px !important;
}

.mr100 {
  margin-right: 100px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

/** Paddings 1-10(+1), 15-50(+5), 60-100(+10) **/
.p0 {
  padding: 0px !important;
}

.p1 {
  padding: 1px !important;
}

.pl1 {
  padding-left: 1px !important;
}

.pr1 {
  padding-right: 1px !important;
}

.pt1 {
  padding-top: 1px !important;
}

.pb1 {
  padding-bottom: 1px !important;
}

.p2 {
  padding: 2px !important;
}

.pl2 {
  padding-left: 2px !important;
}

.pr2 {
  padding-right: 2px !important;
}

.pt2 {
  padding-top: 2px !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.p3 {
  padding: 3px !important;
}

.pl3 {
  padding-left: 3px !important;
}

.pr3 {
  padding-right: 3px !important;
}

.pt3 {
  padding-top: 3px !important;
}

.pb3 {
  padding-bottom: 3px !important;
}

.p4 {
  padding: 4px !important;
}

.pl4 {
  padding-left: 4px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.pb4 {
  padding-bottom: 4px !important;
}

.p5 {
  padding: 5px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.p6 {
  padding: 6px !important;
}

.pl6 {
  padding-left: 6px !important;
}

.pr6 {
  padding-right: 6px !important;
}

.pt6 {
  padding-top: 6px !important;
}

.pb6 {
  padding-bottom: 6px !important;
}

.p7 {
  padding: 7px !important;
}

.pl7 {
  padding-left: 7px !important;
}

.pr7 {
  padding-right: 7px !important;
}

.pt7 {
  padding-top: 7px !important;
}

.pb7 {
  padding-bottom: 7px !important;
}

.p8 {
  padding: 8px !important;
}

.pl8 {
  padding-left: 8px !important;
}

.pr8 {
  padding-right: 8px !important;
}

.pt8 {
  padding-top: 8px !important;
}

.pb8 {
  padding-bottom: 8px !important;
}

.p9 {
  padding: 9px !important;
}

.pl9 {
  padding-left: 9px !important;
}

.pr9 {
  padding-right: 9px !important;
}

.pt9 {
  padding-top: 9px !important;
}

.pb9 {
  padding-bottom: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.p25 {
  padding: 25px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.p30 {
  padding: 30px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.p35 {
  padding: 35px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.p40 {
  padding: 40px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.p45 {
  padding: 45px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.p50 {
  padding: 50px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.p60 {
  padding: 60px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.p70 {
  padding: 70px !important;
}

.pl70 {
  padding-left: 70px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.p80 {
  padding: 80px !important;
}

.pl80 {
  padding-left: 80px !important;
}

.pr80 {
  padding-right: 80px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.p90 {
  padding: 90px !important;
}

.pl90 {
  padding-left: 90px !important;
}

.pr90 {
  padding-right: 90px !important;
}

.pt90 {
  padding-top: 90px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.p100 {
  padding: 100px !important;
}

.pl100 {
  padding-left: 100px !important;
}

.pr100 {
  padding-right: 100px !important;
}

.pt100 {
  padding-top: 100px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

/** Widths 1-10(+1), 15-100(+5), 110-200(+10), 220-300(+20), 350-1000(+50) **/
.w1 {
  width: 1px !important;
}

.w2 {
  width: 2px !important;
}

.w3 {
  width: 3px !important;
}

.w4 {
  width: 4px !important;
}

.w5 {
  width: 5px !important;
}

.w6 {
  width: 6px !important;
}

.w7 {
  width: 7px !important;
}

.w8 {
  width: 8px !important;
}

.w9 {
  width: 9px !important;
}

.w10 {
  width: 10px !important;
}

.w15 {
  width: 15px !important;
}

.w20 {
  width: 20px !important;
}

.w25 {
  width: 25px !important;
}

.w30 {
  width: 30px !important;
}

.w35 {
  width: 35px !important;
}

.w40 {
  width: 40px !important;
}

.w45 {
  width: 45px !important;
}

.w50 {
  width: 50px !important;
}

.w55 {
  width: 55px !important;
}

.w60 {
  width: 60px !important;
}

.w65 {
  width: 65px !important;
}

.w70 {
  width: 70px !important;
}

.w75 {
  width: 75px !important;
}

.w80 {
  width: 80px !important;
}

.w85 {
  width: 85px !important;
}

.w90 {
  width: 90px !important;
}

.w95 {
  width: 95px !important;
}

.w100 {
  width: 100px !important;
}

.w110 {
  width: 110px !important;
}

.w120 {
  width: 120px !important;
}

.w130 {
  width: 130px !important;
}

.w140 {
  width: 140px !important;
}

.w150 {
  width: 150px !important;
}

.w160 {
  width: 160px !important;
}

.w170 {
  width: 170px !important;
}

.w180 {
  width: 180px !important;
}

.w190 {
  width: 190px !important;
}

.w200 {
  width: 200px !important;
}

.w220 {
  width: 220px !important;
}

.w240 {
  width: 240px !important;
}

.w250 {
  width: 250px !important;
}

.w260 {
  width: 260px !important;
}

.w280 {
  width: 280px !important;
}

.w300 {
  width: 300px !important;
}

.w350 {
  width: 350px !important;
}

.w400 {
  width: 400px !important;
}

.w450 {
  width: 450px !important;
}

.w500 {
  width: 500px !important;
}

.w550 {
  width: 550px !important;
}

.w600 {
  width: 600px !important;
}

.w650 {
  width: 650px !important;
}

.w700 {
  width: 700px !important;
}

.w750 {
  width: 750px !important;
}

.w800 {
  width: 800px !important;
}

.w850 {
  width: 850px !important;
}

.w900 {
  width: 900px !important;
}

.w950 {
  width: 950px !important;
}

.w1000 {
  width: 1000px !important;
}

.minw1 {
  min-width: 1px !important;
}

.minw2 {
  min-width: 2px !important;
}

.minw3 {
  min-width: 3px !important;
}

.minw4 {
  min-width: 4px !important;
}

.minw5 {
  min-width: 5px !important;
}

.minw6 {
  min-width: 6px !important;
}

.minw7 {
  min-width: 7px !important;
}

.minw8 {
  min-width: 8px !important;
}

.minw9 {
  min-width: 9px !important;
}

.minw10 {
  min-width: 10px !important;
}

.minw15 {
  min-width: 15px !important;
}

.minw20 {
  min-width: 20px !important;
}

.minw25 {
  min-width: 25px !important;
}

.minw30 {
  min-width: 30px !important;
}

.minw35 {
  min-width: 35px !important;
}

.minw40 {
  min-width: 40px !important;
}

.minw45 {
  min-width: 45px !important;
}

.minw50 {
  min-width: 50px !important;
}

.minw55 {
  min-width: 55px !important;
}

.minw60 {
  min-width: 60px !important;
}

.minw65 {
  min-width: 65px !important;
}

.minw70 {
  min-width: 70px !important;
}

.minw75 {
  min-width: 75px !important;
}

.minw80 {
  min-width: 80px !important;
}

.minw85 {
  min-width: 85px !important;
}

.minw90 {
  min-width: 90px !important;
}

.minw95 {
  min-width: 95px !important;
}

.minw100 {
  min-width: 100px !important;
}

.minw110 {
  min-width: 110px !important;
}

.minw120 {
  min-width: 120px !important;
}

.minw130 {
  min-width: 130px !important;
}

.minw140 {
  min-width: 140px !important;
}

.minw150 {
  min-width: 150px !important;
}

.minw160 {
  min-width: 160px !important;
}

.minw170 {
  min-width: 170px !important;
}

.minw180 {
  min-width: 180px !important;
}

.minw190 {
  min-width: 190px !important;
}

.minw200 {
  min-width: 200px !important;
}

.minw220 {
  min-width: 220px !important;
}

.minw240 {
  min-width: 240px !important;
}

.minw250 {
  min-width: 250px !important;
}

.minw260 {
  min-width: 260px !important;
}

.minw280 {
  min-width: 280px !important;
}

.minw300 {
  min-width: 300px !important;
}

.minw350 {
  min-width: 350px !important;
}

.minw400 {
  min-width: 400px !important;
}

.minw450 {
  min-width: 450px !important;
}

.minw500 {
  min-width: 500px !important;
}

.minw550 {
  min-width: 550px !important;
}

.minw600 {
  min-width: 600px !important;
}

.minw650 {
  min-width: 650px !important;
}

.minw700 {
  min-width: 700px !important;
}

.minw750 {
  min-width: 750px !important;
}

.minw800 {
  min-width: 800px !important;
}

.minw850 {
  min-width: 850px !important;
}

.minw900 {
  min-width: 900px !important;
}

.minw950 {
  min-width: 950px !important;
}

.minw1000 {
  min-width: 1000px !important;
}

.w-auto {
  width: auto !important;
}

/** Width 1% to 100% **/
.wper0 {
  width: 0% !important;
  overflow: hidden !important
}

.wper1 {
  width: 1% !important;
  overflow: hidden !important
}

.wper2 {
  width: 2% !important;
  overflow: hidden !important
}

.wper3 {
  width: 3% !important;
  overflow: hidden !important
}

.wper4 {
  width: 4% !important;
  overflow: hidden !important
}

.wper5 {
  width: 5% !important;
  overflow: hidden !important
}

.wper6 {
  width: 6% !important;
  overflow: hidden !important
}

.wper7 {
  width: 7% !important;
  overflow: hidden !important
}

.wper8 {
  width: 8% !important;
  overflow: hidden !important
}

.wper9 {
  width: 9% !important;
  overflow: hidden !important
}

.wper10 {
  width: 10% !important;
  overflow: hidden !important
}

.wper11 {
  width: 11% !important;
  overflow: hidden !important
}

.wper12 {
  width: 12% !important;
  overflow: hidden !important
}

.wper13 {
  width: 13% !important;
  overflow: hidden !important
}

.wper14 {
  width: 14% !important;
  overflow: hidden !important
}

.wper15 {
  width: 15% !important;
  overflow: hidden !important
}

.wper16 {
  width: 16% !important;
  overflow: hidden !important
}

.wper17 {
  width: 17% !important;
  overflow: hidden !important
}

.wper18 {
  width: 18% !important;
  overflow: hidden !important
}

.wper19 {
  width: 19% !important;
  overflow: hidden !important
}

.wper20 {
  width: 20% !important;
  overflow: hidden !important
}

.wper21 {
  width: 21% !important;
  overflow: hidden !important
}

.wper22 {
  width: 22% !important;
  overflow: hidden !important
}

.wper23 {
  width: 23% !important;
  overflow: hidden !important
}

.wper24 {
  width: 24% !important;
  overflow: hidden !important
}

.wper25 {
  width: 25% !important;
  overflow: hidden !important
}

.wper26 {
  width: 26% !important;
  overflow: hidden !important
}

.wper27 {
  width: 27% !important;
  overflow: hidden !important
}

.wper28 {
  width: 28% !important;
  overflow: hidden !important
}

.wper29 {
  width: 29% !important;
  overflow: hidden !important
}

.wper30 {
  width: 30% !important;
  overflow: hidden !important
}

.wper31 {
  width: 31% !important;
  overflow: hidden !important
}

.wper32 {
  width: 32% !important;
  overflow: hidden !important
}

.wper33 {
  width: 33% !important;
  overflow: hidden !important
}

.wper34 {
  width: 34% !important;
  overflow: hidden !important
}

.wper35 {
  width: 35% !important;
  overflow: hidden !important
}

.wper36 {
  width: 36% !important;
  overflow: hidden !important
}

.wper37 {
  width: 37% !important;
  overflow: hidden !important
}

.wper38 {
  width: 38% !important;
  overflow: hidden !important
}

.wper39 {
  width: 39% !important;
  overflow: hidden !important
}

.wper40 {
  width: 40% !important;
  overflow: hidden !important
}

.wper41 {
  width: 41% !important;
  overflow: hidden !important
}

.wper42 {
  width: 42% !important;
  overflow: hidden !important
}

.wper43 {
  width: 43% !important;
  overflow: hidden !important
}

.wper44 {
  width: 44% !important;
  overflow: hidden !important
}

.wper45 {
  width: 45% !important;
  overflow: hidden !important
}

.wper46 {
  width: 46% !important;
  overflow: hidden !important
}

.wper47 {
  width: 47% !important;
  overflow: hidden !important
}

.wper48 {
  width: 48% !important;
  overflow: hidden !important
}

.wper49 {
  width: 49% !important;
  overflow: hidden !important
}

.wper50 {
  width: 50% !important;
  overflow: hidden !important
}

.wper51 {
  width: 51% !important;
  overflow: hidden !important
}

.wper52 {
  width: 52% !important;
  overflow: hidden !important
}

.wper53 {
  width: 53% !important;
  overflow: hidden !important
}

.wper54 {
  width: 54% !important;
  overflow: hidden !important
}

.wper55 {
  width: 55% !important;
  overflow: hidden !important
}

.wper56 {
  width: 56% !important;
  overflow: hidden !important
}

.wper57 {
  width: 57% !important;
  overflow: hidden !important
}

.wper58 {
  width: 58% !important;
  overflow: hidden !important
}

.wper59 {
  width: 59% !important;
  overflow: hidden !important
}

.wper60 {
  width: 60% !important;
  overflow: hidden !important
}

.wper61 {
  width: 61% !important;
  overflow: hidden !important
}

.wper62 {
  width: 62% !important;
  overflow: hidden !important
}

.wper63 {
  width: 63% !important;
  overflow: hidden !important
}

.wper64 {
  width: 64% !important;
  overflow: hidden !important
}

.wper65 {
  width: 65% !important;
  overflow: hidden !important
}

.wper66 {
  width: 66% !important;
  overflow: hidden !important
}

.wper67 {
  width: 67% !important;
  overflow: hidden !important
}

.wper68 {
  width: 68% !important;
  overflow: hidden !important
}

.wper69 {
  width: 69% !important;
  overflow: hidden !important
}

.wper70 {
  width: 70% !important;
  overflow: hidden !important
}

.wper71 {
  width: 71% !important;
  overflow: hidden !important
}

.wper72 {
  width: 72% !important;
  overflow: hidden !important
}

.wper73 {
  width: 73% !important;
  overflow: hidden !important
}

.wper74 {
  width: 74% !important;
  overflow: hidden !important
}

.wper75 {
  width: 75% !important;
  overflow: hidden !important
}

.wper76 {
  width: 76% !important;
  overflow: hidden !important
}

.wper77 {
  width: 77% !important;
  overflow: hidden !important
}

.wper78 {
  width: 78% !important;
  overflow: hidden !important
}

.wper79 {
  width: 79% !important;
  overflow: hidden !important
}

.wper80 {
  width: 80% !important;
  overflow: hidden !important
}

.wper81 {
  width: 81% !important;
  overflow: hidden !important
}

.wper82 {
  width: 82% !important;
  overflow: hidden !important
}

.wper83 {
  width: 83% !important;
  overflow: hidden !important
}

.wper84 {
  width: 84% !important;
  overflow: hidden !important
}

.wper85 {
  width: 85% !important;
  overflow: hidden !important
}

.wper86 {
  width: 86% !important;
  overflow: hidden !important
}

.wper87 {
  width: 87% !important;
  overflow: hidden !important
}

.wper88 {
  width: 88% !important;
  overflow: hidden !important
}

.wper89 {
  width: 89% !important;
  overflow: hidden !important
}

.wper90 {
  width: 90% !important;
  overflow: hidden !important
}

.wper91 {
  width: 91% !important;
  overflow: hidden !important
}

.wper92 {
  width: 92% !important;
  overflow: hidden !important
}

.wper93 {
  width: 93% !important;
  overflow: hidden !important
}

.wper94 {
  width: 94% !important;
  overflow: hidden !important
}

.wper95 {
  width: 95% !important;
  overflow: hidden !important
}

.wper96 {
  width: 96% !important;
  overflow: hidden !important
}

.wper97 {
  width: 97% !important;
  overflow: hidden !important
}

.wper98 {
  width: 98% !important;
  overflow: hidden !important
}

.wper99 {
  width: 99% !important;
  overflow: hidden !important
}

.wper100 {
  width: 100% !important;
  overflow: hidden !important
}

.mw-100 {
  max-width: 100%;
}

.a4width {
  width: 21cm
}

/** Font Sizes **/
.fs9 {
  font-size: 9px
}

.fs10 {
  font-size: 10px !important
}

.fs11 {
  font-size: 11px !important
}

.fs12 {
  font-size: 12px !important
}

.fs13 {
  font-size: 13px !important
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px !important
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fs26 {
  font-size: 26px;
}

.fs28 {
  font-size: 28px;
}

.fs30 {
  font-size: 30px;
}

.fs34 {
  font-size: 34px;
}

.fs36 {
  font-size: 36px;
}

.fs40 {
  font-size: 40px;
}

a,
.link {
  color: var(--anchor-color);
  cursor: pointer;
}

.breakword {
  word-break: break-all !important;
}

.nowrap {
  white-space: nowrap;
}

.bold {
  font-weight: bold;
}

.bold300 {
  font-weight: 300;
}

.bold400 {
  font-weight: 400
}

.bold500 {
  font-weight: 500
}

.bold600 {
  font-weight: 600;
}

.bold700 {
  font-weight: 700;
}

.bold800 {
  font-weight: 800;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.req::after {
  content: ' *';
  color: #f3565d !important;
}

.noselect {
  user-select: none;
}

.cpointer {
  cursor: pointer;
}

.uc {
  text-transform: uppercase;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.overflow-hidden {
  overflow: hidden;
}

.d-none {
  display: none;
}

.text-secondary {
  color: #888
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-danger {
  color: red
}

.text-success {
  color: #87d068
}

.text-white {
  color: #fff
}

.form-group {
  margin-bottom: 10px;
}

.bg-white {
  background-color: #fff;
}

/** D-Flex **/
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important
}

.mr-auto,
.mx-auto {
  margin-right: auto !important
}

.ml-auto,
.mx-auto {
  margin-left: auto !important
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important
}

.mt-auto,
.my-auto {
  margin-top: auto !important
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important
}

.order-1 {
  -ms-flex-order: 1;
  order: 1
}

.order-2 {
  -ms-flex-order: 2;
  order: 2
}

.order-3 {
  -ms-flex-order: 3;
  order: 3
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important
}

.m-auto {
  margin: auto;
}

/** \ **/

.cscroll,
.cscroll .ant-card-body,
.cscroll .ant-modal-body {
  overflow: auto;
}

.cscroll::-webkit-scrollbar,
.cscroll .ant-card-body::-webkit-scrollbar,
.cscroll .ant-modal-body::-webkit-scrollbar {
  background: none;
  width: 6px;
  height: 6px;
  border-radius: 0px
}

.cscroll::-webkit-scrollbar-thumb,
.cscroll .ant-card-body::-webkit-scrollbar-thumb,
.cscroll .ant-modal-body::-webkit-scrollbar-thumb {
  background: var(--cscroll-thumb-bg);
  transition: 300ms ease all;
  border-radius: 0px
}

.cscroll::-webkit-scrollbar-thumb:hover,
.cscroll .ant-card-body::-webkit-scrollbar-thumb:hover,
.cscroll .ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: var(--cscroll-thumb-bg-hover)
}

/** Antd Override **/
.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-left: 5px;
}

.ant-badge {
  margin: -3px 0 0 0;
}

.ant-input-focused,
.ant-input:focus,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-focused .ant-select-selector,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus,
.ant-picker-focused {
  box-shadow: none !important;
  outline: none !important;
}

.ant-modal {
  transform-origin: unset !important
}

.ant-modal-close,
.ant-modal-close:focus,
.ant-modal-close:active,
.ant-drawer-close,
.ant-drawer-close:focus,
.ant-drawer-close:active {
  box-shadow: none;
  outline: none;
}

.ant-card-small .ant-card-actions>li {
  margin: 8px 0;
}

.ant-table,
.ant-card {
  line-height: 1.3;
}

.ant-table-cell-scrollbar {
  box-shadow: none;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  content: unset;
}

.ant-table-body {
  overflow: auto !important;
}

.ant-tbl-body-scroll .ant-table-body {
  overflow: scroll !important;
}

.ant-table-thead>tr>th {
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
}

.ant-table.ant-table-small {
  font-size: 13px;
}

.ant-table.ant-table-small .ant-table-thead>tr>th {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.ant-tag {
  margin: 0;
}

.ant-menu .ant-menu-item {
  margin: 0 !important;
}

.ant-menu a {
  text-decoration: none
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  margin: 0 !important;
}

.ant-modal-header {
  padding: 14px 20px;
}

.ant-modal-body {
  padding: 14px 20px;
  overflow: auto;
}

.ant-modal-footer {
  padding: 10px 20px;
}

.ant-modal-close-x {
  height: 50px;
  line-height: 50px;
}

.ant-drawer-header {
  padding-top: 14px;
  padding-bottom: 14px;
}

.ant-modal-close,
.ant-modal-close:focus,
.ant-modal-close:active,
.ant-drawer-close,
.ant-drawer-close:focus,
.ant-drawer-close:active {
  box-shadow: none;
  outline: none;
}

.ant-card-head-title,
.ant-modal-title,
.ant-drawer-title,
.ant-divider-horizontal.ant-divider-with-text {
  font-weight: 600;
}

.ant-card-body {
  overflow: auto
}

/** **/
.ant-menu {
  background: var(--navbar-bg);
}

.ant-menu-horizontal {
  border-bottom: none;
  line-height: 36px;
  font-size: 14px;
  font-weight: 600;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
  padding: 0 15px
}

.ant-menu-horizontal>.ant-menu-item:after,
.ant-menu-horizontal>.ant-menu-submenu:after {
  left: 15px;
  right: 15px;
}

/* .ant-menu-horizontal>.ant-menu-item-selected.ant-menu-item:after{
  border-bottom: 2px solid transparent !important;
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after{
  border-bottom: 2px solid transparent !important;
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
  border-bottom: 2px solid transparent !important;
} */

.ant-pagination-pad .ant-pagination {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-table-cell-scrollbar {
  box-shadow: none;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  content: unset;
}

.ant-table-text-top table td {
  vertical-align: top;
}

.antd-bdr-tab .ant-tabs-nav {
  margin: 0;
}

.antd-bdr-tab .ant-tabs-content {
  border: 1px solid #f0f0f0;
  border-top: none;
  padding: 15px;
}

.ant-tag.sm {
  font-size: 9px;
  padding: 2px;
  line-height: 12px;
}

.ant-tag.md {
  font-size: 11px;
  padding: 4px;
  line-height: 12px;
}

.ant-card .minus-lr {
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
}

.ant-card.ant-card-small .minus-lr {
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
}

.ant-table-summary {
  background: #fafafa !important;
}

tfoot th,
tfoot td {
  background: #fafafa !important;
  font-weight: 600;
}

[data-theme="dark"] .ant-table-summary {
  background: #1d1d1d !important;
}

[data-theme="dark"] tfoot th,
[data-theme="dark"] tfoot td {
  background: #1d1d1d !important;
}

#root {
  position: relative;
}

label {
  margin-bottom: 3px;
  display: block;
}

.navbar-default {
  border: none;
  background-color: var(--navbar-bg);
  color: var(--font-color);
  z-index: 100;
  width: 100%;
  height: 50px;
  /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
  position: sticky;
  left: 0;
  top: 0;
  padding: 0 20px;
}

.navbar-default::after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  top: 100%;
  height: 4px;
  background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
}

.tbl-usr-pic img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.page-heading {
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.paging-box {
  padding: 10px 8px;
}

.above-tbl-filter-pad {
  padding: 12px 8px;
}

.bdr {
  border: 1px solid var(--border-color);
}

.bdr-top {
  border-top: 1px solid var(--border-color);
}

.bdr-bottom {
  border-bottom: 1px solid var(--border-color);
}

.bdr-left {
  border-left: 1px solid var(--border-color);
}

.bdr-right {
  border-right: 1px solid var(--border-color);
}

.bdr-radius {
  border-radius: 2px
}

.main-content-pad {
  padding: 20px 20px 15px 20px;
}

.loginpage {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}


/** Application **/
.search-multi-btn-pills div {
  background: var(--pills-bg);
  height: 32px;
  line-height: 32px;
  margin-right: 1px;
  padding: 0 10px;
  font-size: 12px;
  cursor: pointer;
}

.search-multi-btn-pills div.active {
  background: var(--pills-bg-active);
  color: #fff;
}

.search-multi-btn-pills div:first-child {
  border-radius: 2px 0 0 2px;
}

.search-multi-btn-pills div:last-child {
  border-radius: 0 2px 2px 0;
}

.active-list-item {
  background: var(--active-list-item-bg);
  color: #fff;
}

.tab-pill {
  background: #d5cdcd;
  color: #333;
  text-align: center;
  font-weight: 400;
  padding: 3px 8px;
  border-radius: 16px;
}

.lead-status {
  background: #f1f1f1;
  color: #333;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  padding: 2px 8px;
  border-radius: 10px;
}

/** \ **/

.tr-lbl-sale {
  color: rgb(251, 118, 123);
  font-weight: 700
}

.tr-lbl-tp {
  color: #999;
  font-weight: 700;
}

.tr-lbl-buy {
  color: #32CD32;
  font-weight: 700
}

.legend-lbl {
  position: relative;
}

.legend-lbl>label {
  position: absolute;
  z-index: 2;
  left: 6px;
  top: -8px;
  padding: 0 4px;
  background: #fff;
  color: #999;
  font-size: 11px;
}

/** MyTbl **/
.mytbl table {
  width: 100%;
  border-collapse: collapse;
}

.mytbl table.bdr th,
.mytbl table.bdr td {
  border: 1px solid #eee
}

.mytbl table.bdr-dark th,
.mytbl table.bdr-dark td {
  border: 1px solid #777
}

.mytbl table th {
  padding: 3px 5px;
  font-weight: 600;
  color: #333;
  background: #ccc
}

.mytbl table.ltdarkhead th {
  background: #888;
  color: #fff
}

.mytbl table td {
  padding: 3px 5px
}

.mytbl table.sm td {
  padding: 1px 5px;
  font-size: 13px;
}

.mytbl table td {
  background: #FFFFFF
}

.mytbl table.nobdr th,
.mytbl table.nobdr td {
  border: none
}

/** \ **/

.twolineellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.lineellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.lineellipsis.line1 {
  -webkit-line-clamp: 1;
}

.lineellipsis.line2 {
  -webkit-line-clamp: 2;
}

.lineellipsis.line3 {
  -webkit-line-clamp: 3;
}

.lineellipsis.line4 {
  -webkit-line-clamp: 4;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.marquee {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.marquee div {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}