.publicpage{position: fixed; left: 0; top: 0; width: 100%; height: 100%; overflow: auto; z-index: 99; background: #fff;}
.hpcontainer{padding: 0 24px;}

/** Header **/
.hdr-top-strip{background:#333; color:#fff; font-weight: 600;}
.hdr-top-strip .ant-menu-horizontal>.ant-menu-item a{color: #fff;}
.hdr-top-strip .ant-menu{background: transparent; color:rgba(255, 255, 255, 1)}
.hdr-top-strip .ant-menu-overflow{justify-content: end;}
.hdr-grey-bg{padding-top: 10px; padding-bottom: 10px; background: #f5f5f5;}
/* .hdr-nav a{
  margin-right: 15px; 
  padding: 7px 16px;
  text-align: center;
  display: block;
  width: 130px;
  font-weight: 600;
  background: rgba(209, 241, 247, 0.3);
  color: rgb(0, 149, 231);
  border:1px solid rgb(119, 210, 238);
  border-radius: 4px;
  box-shadow: rgb(255 255 255 / 50%) 0.2em 0.2em 0.2em 0px inset, rgb(0 0 0 / 50%) -0.2em -0.2em 0.2em 0px inset;
} */

.hdr-nav a{
  margin-right: 15px; 
  padding: 7px 16px;
  text-align: center;
  display: block;
  font-weight: 600;
  color:#555;
  font-size: 18px;
}
.hdr-nav a.active{
  color: rgb(0, 149, 231);
  background: rgba(20, 149, 231, 0.1);
  border-radius: 6px;
}
/** \ **/

.sldr-arrow{position: absolute; z-index: 1; top: 50%; transform: translateY(-50%); color: #fff; font-size: 36px; background: rgba(0,0,0, 0.2);}
.sldr-arrow.arr-left{left:20px;}
.sldr-arrow.arr-right{right:20px;}

.home-pro-tabs .ant-tabs-content{padding-bottom: 0 !important;}
/* .home-pro-tabs .symbol-bx{width:130px; padding: 3px 8px; text-align: center; background: teal; color: #fff; font-size: 12px;} */
.home-pro-tabs-active-symbol{background: rgba(0, 149, 231, 0.1);}
.home-pro-tabs .ant-tabs-content-holder{overflow: hidden;}

.act-tinterval{text-decoration: underline;}

.ant-input.material, 
.ant-select.material .ant-select-selector, 
.ant-picker.material, 
.ant-input-password.material{border: none; border-bottom: 1px solid #333; border-radius: 0; padding-left: 0 !important; padding-right: 0 !important; background: transparent !important;}
.ant-select.material .ant-select-arrow{right: 0;}

.ant-input.material.whitetext{border-bottom: 1px solid #f1f1f1; color: #f1f1f1;}

.ant-input-affix-wrapper.material:not(.ant-input-affix-wrapper-disabled):hover,
.ant-select.material:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: unset;
}

.ant-input.material.aipl30{padding-left: 30px !important;}

.home-chart-minmax-btn{background: #27272b; font-weight: bold; font-size: 20px; width: 30px; height: 24px; color: #fff; border: 1px solid red; position: absolute; z-index: 2; cursor: pointer;}

.pro-tabs-cards .ant-card-head, .pro-tabs-cards .ant-card-head-wrapper{min-height: 28px !important;}
.pro-tabs-cards .ant-card-head-title{padding: 8px 0 !important;}

.strip-info1{background: #1890ff; color:#fff; margin: 0 0 0px 0; padding: 8px 20px; border-radius: 4px 4px 0 0;}

.noellipsis-title .ant-card-head-title {
    display: inline-block;
    flex: unset;
    padding: 16px 0;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
}

.noellipsis-title .ant-card-head{
  background: #f5f5f5;
}

.public-footer{border-top:2px solid #ccc; background: #f5f5f5; padding: 15px 0 0 0;}

@media (max-width:768px) {
    .hpcontainer{padding: 0 12px;}
    .hdr-top-strip .ant-menu-overflow{justify-content: start;}
    /* .hdr-nav{overflow: auto; width: 100%;} */
    /* .hdr-nav a{flex-basis: 130px; flex-grow: 0; flex-shrink: 0;} */
}